import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "animate.css/animate.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "./components/Layout";
import SupportPage from "./pages/Support";
import CredentialInventoryPage from "./pages/Monitoring/Inventory";
import AnalyticsPage from "./pages/Analytics";
import UserManagementPage from "./pages/UserManagement";
import KioskManagementPage from "./pages/KioskManagement";
import KioskMaintenancePage from "./pages/KioskMaintenance";
import ValidatePage from "./pages/Validate";
import GeneralPage from "./pages/General";
import ImportPage from "./pages/Settings/Import";
import ScheduledReportsPage from "./pages/Settings/ScheduledReports";
import NotificationsPage from "./pages/Settings/Notifications";
import SystemPage from "./pages/Settings/System";
import IntegratorSetup from "./pages/Settings/IntegratorSetup";
import StatusSyncPage from "./pages/Access/StatusSync";
import AccessGroupsPage from "./pages/Access/AccessGroups";
import AddPage from "./pages/Access/Add";
import OnboardingPage from "./pages/Onboarding";
import DeviceControlPage from "./pages/Monitoring/DeviceControl";
import DeviceControlV2Page from "./pages/Monitoring/DeviceControlV2/DeviceControlV2Page";
import AuditToolPage from "./pages/Monitoring/AuditTool";
import TransactionsPage from "./pages/Monitoring/Transactions";
import AlertsPage from "./pages/Monitoring/Alerts";
import LoginPage from "./pages/Login";
import EventsPage from "./pages/Events";
import ProtectedRoute from "./components/ProtectedRoute";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "typeface-roboto";
import AccountManagementPage from "./pages/AccountManagement";
import TaxesManagementPage from "./pages/TaxesManagement";
import useAuthContext from "./hooks/useAuthContext";
import ContractManagement from "././pages/ContractManagement";
import EntityTree from "./pages/EntityTree";
import SettingsPage from "./pages/Settings";
import AddEditGateway from "./pages/GatewayManagement";
import { SnackbarProvider } from "notistack";
import ValidationAccountsPage from "./pages/ValidationAccounts";
import { RoutineServiceRouter } from "./pages/RoutineService";
import ReportsPage from "./pages/Reports";
import CallCenterPage from "./pages/CallCenter";
import HomePage from "./pages/Home";
import MobilePayPage from "./pages/MobilePayment";
import UserProvider from "./providers/UserProvider";
import { PayOnEntryPage } from "./pages/PayOnEntry";
import { CashierPage } from "./pages/Cashier";
import { RateManagement } from "./pages/Rates";
import AdministrationPage from "./pages/Administration";
import AdminUsersPage from "./pages/Administration/Users/index";
import { AuthStatus } from "./reducers/auth/authReducer";
import GroupContractsPage from "./pages/GroupContractsPage";
import FPAPage from "./pages/FlexPayment/index";
import CardOnFilePage from "./pages/CardOnFile/index";
import ShiftSessionPage from "./pages/ShiftSession/index";
import CashierDevice from "./pages/CashierDevice";
import UserConfirmation from "./components/UserConfirmation";
import DeviceVersions from "./pages/Administration/Versions/DeviceVersions";
import PlaygroundLandingPage from "./pages/Administration/Playground";
import FlagTestLandingPage from "./pages/Administration/FlagTest";
import DeployableScriptsPage from "./pages/Administration/DeployableScripts";
import UpdateNotification from "./components/UpdateNotification";
import FacilityActivityPage from "./pages/Administration/FacilityActivity";
import {useFeatureFlag} from "./hooks/useFeatureFlags";
import {useFlags} from "launchdarkly-react-client-sdk";
import { MaintenancePage } from "./pages/MaintenancePage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: theme.palette.background.level1,
  },
}));

const RootContainer = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export const isGuid = (value) => {
  const isGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return isGuid.test(value);
};



const App = () => {
  const { authReducer } = useAuthContext();
  const [authState] = authReducer;
  // I know that there is the nav list to perform the swap but with the current logic this i believe is this the best path
  const enableDeviceControl = useFeatureFlag('Features.DeviceControlv2');
  const { systemOffline } = useFlags();

  return (
    <SnackbarProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <RootContainer>
          <UpdateNotification/>
          <CssBaseline />
          <Router
            getUserConfirmation={(message, callback) =>
              UserConfirmation(message, callback)
            }
          >
            <Switch>
              <Route path="/mobilepay/scanagain">
                <MobilePayPage scanAgain={true} />
              </Route>
              <Route path="/mobilepay/:ticketId">
                <MobilePayPage scanAgain={false} />
              </Route>

              <Route path="/fpapay/:accessHolderId">
                <FPAPage />
              </Route>

              <Route path="/login">
                <UserProvider>
                  <LoginPage />
                </UserProvider>
              </Route>

              <Route path="/offline">
                <MaintenancePage />
              </Route>

              <Route path="/">
                {systemOffline?.enabled ? (
                  <Redirect exact from="/" to={"/offline"} />
                ) : (
                  authState.authStatus === AuthStatus.UNAUTHENTICATED ? (
                    <Redirect exact from="/" to={"/login"} />
                  ) : (
                    <UserProvider>
                      <Layout>
                        <Switch>
                          <ProtectedRoute component={HomePage} path="/home" />

                          <ProtectedRoute path="/cardonfile/:returnPath/:contractID/:accessHolderID?">
                            <CardOnFilePage />
                          </ProtectedRoute>

                          <ProtectedRoute
                            component={CallCenterPage}
                            path="/call"
                            permissions={["callcenter.view"]}
                          />

                          <ProtectedRoute
                            component={EntityTree}
                            path="/tree"
                            permissions={["overview.view"]}
                          />

                          <ProtectedRoute
                            component={AddEditGateway}
                            path="/gateway"
                          />

                          <ProtectedRoute
                            component={AccountManagementPage}
                            path="/accounts"
                          />

                          <ProtectedRoute
                            component={AnalyticsPage}
                            path="/analytics"
                          />

                          {/* Dashboard page is actually the archived Analytics Page */}
                          <ProtectedRoute
                            component={AnalyticsPage}
                            path="/dashboard"
                          />

                          <ProtectedRoute
                            component={KioskMaintenancePage}
                            path="/kioskMaintenance"
                          />

                          <ProtectedRoute
                            component={KioskManagementPage}
                            path="/kioskManagement"
                          />

                          <ProtectedRoute
                            component={RateManagement}
                            path="/rates"
                            permissions={["rates.view"]}
                          />

                          <ProtectedRoute
                            component={PayOnEntryPage}
                            path="/payonentry"
                            permissions={["payonentry.view"]}
                          />

                          <ProtectedRoute
                            path="/cashieredDevice/:stepName?"
                            component={CashierDevice}
                            permissions={[
                              "cashiermodes.payonentrycashier",
                              "cashiermodes.roamingcashier",
                            ]}
                            hasAny={true}
                          />

                          <ProtectedRoute
                            component={SupportPage}
                            path="/support"
                          />

                          <ProtectedRoute
                            component={UserManagementPage}
                            path="/users"
                          />
                          <ProtectedRoute
                            component={EventsPage}
                            path="/events"
                            permissions={["rates.view"]}
                          />
                          <ProtectedRoute
                            component={ContractManagement}
                            path="/contracts"
                          />
                          <ProtectedRoute
                            component={ValidationAccountsPage}
                            path="/validationaccounts"
                          />
                          <ProtectedRoute
                            component={CashierPage}
                            path="/cashier"
                            permissions={[
                              "cashiersessions.view",
                              "roamingcreditcardterminalsetup.view",
                            ]}
                            hasAny
                          />
                          <ProtectedRoute
                            component={RoutineServiceRouter}
                            path="/routineservice"
                            permissions={["routines.view"]}
                          />
                          <ProtectedRoute
                            component={SettingsPage}
                            path="/settings"
                          />

                          <ProtectedRoute
                            component={TaxesManagementPage}
                            path="/taxmgmt"
                            permissions={["taxes.view"]}
                          />

                          <ProtectedRoute
                            component={ValidatePage}
                            path="/validate"
                            permissions={[
                              "createprintedvalidations",
                              "validatetickets",
                              "createonepass"
                            ]}
                            hasAny
                          />

                          <ProtectedRoute
                            component={GeneralPage}
                            path="/general"
                            permissions={["general.view"]}
                          />

                          <ProtectedRoute
                            component={ImportPage}
                            path="/import"
                            permissions={["import.view"]}
                          />

                          <ProtectedRoute
                            component={ScheduledReportsPage}
                            path="/scheduledreports"
                            permissions={["ScheduledReports"]}
                          />

                          <ProtectedRoute
                            component={NotificationsPage}
                            path="/notifications"
                            permissions={["notifications.view"]}
                          />

                          <ProtectedRoute
                            component={SystemPage}
                            path="/system"
                            permissions={["system.view"]}
                          />
                          <ProtectedRoute
                            component={DeviceVersions}
                            path="/admin/versions"
                          />                         
                          <ProtectedRoute
                            component={AdminUsersPage}
                            path="/admin/users"
                          />  
                          <ProtectedRoute
                            component={PlaygroundLandingPage}
                            path="/admin/playground"
                          />  
                      
                          <ProtectedRoute
                            component={AdministrationPage}
                            path="/admin/dashboard"
                          />

                          <ProtectedRoute
                            component={IntegratorSetup}
                            path="/admin/integrators"
                          />      

                          <ProtectedRoute
                            component={FlagTestLandingPage}
                            path="/admin/flagtest"
                          />

                          <ProtectedRoute
                            component={DeployableScriptsPage}
                            path="/admin/deployable-scripts"
                          />
            
                          <ProtectedRoute
                            component={FacilityActivityPage}
                            path="/admin/facilityactivity"
                          />


                          <ProtectedRoute
                            component={StatusSyncPage}
                            path="/status"
                            permissions={["statussync.view"]}
                          />

                          <ProtectedRoute
                            component={AccessGroupsPage}
                            path="/groups"
                            permissions={["accessgroups.view"]}
                          />

                          <ProtectedRoute
                            component={GroupContractsPage}
                            path="/groupcontracts"
                            permissions={["groupcontracts.view"]}
                          />
                          <ProtectedRoute component={AddPage} path="/add" />

                          <ProtectedRoute
                            component={ShiftSessionPage}
                            path="/shiftsession/:stepName?"
                            permissions={[
                              "cashiermodes.payonentrycashier",
                              "cashiermodes.roamingcashier",
                            ]}
                            hasAny={true}
                          />

                          <ProtectedRoute
                            component={ReportsPage}
                            path="/reports/:reportName"
                          />

                          <ProtectedRoute
                            component={OnboardingPage}
                            path="/onboarding"
                            permissions={["onboarding.view"]}
                          />

                          <ProtectedRoute
                            component={enableDeviceControl ? DeviceControlV2Page : DeviceControlPage}
                            path="/devicecontrol"
                            permissions={["devicecontrol.view"]}
                          />

                          <ProtectedRoute
                            component={AuditToolPage}
                            path="/audittool"
                            permissions={["audittool.view"]}
                          />

                          <ProtectedRoute
                            component={CredentialInventoryPage}
                            path="/inventory"
                            permissions={["inventory.view"]}
                          />

                          <ProtectedRoute
                            component={TransactionsPage}
                            path="/transactions"
                            permissions={["transactions.view"]}
                          />

                          <ProtectedRoute
                            component={AlertsPage}
                            path="/alerts"
                            permissions={["alerts.view"]}
                          />
                          <Route render={() => <Redirect to={`/home`} />} />
                        </Switch>
                      </Layout>
                    </UserProvider>
                  )
                )}
              </Route>
            </Switch>
          </Router>
        </RootContainer>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  );
};

export default App;
